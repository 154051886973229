import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Popover from "@mui/material/Popover";
import UserIcon from "@client/icons/UsersIcon";
import PopoverList from "./PopoverList";
import { Badge } from "@mui/material";
import { useModerationContext } from "@components/providers/ModerationProvider";
import { Can } from "@components/providers/AclProvider";
import { ekosliveServer } from "@client/config";
import { useSelector } from "react-redux";
import { selectUser } from "@redux/selectors";
import { captureException } from "@sentry/browser";

const UserPopover = () =>
{
	const { setCount, count } = useModerationContext();
	const { palette } = useTheme();
	const { pathname } = useLocation();
	const [anchorEl, setAnchorEl] = useState(null);
	const user = useSelector(selectUser);

	const handleClick = (event) =>
	{
		setAnchorEl(event.currentTarget);
	};

	const handleClose = useCallback(() =>
	{
		setAnchorEl(null);
	}, [setAnchorEl]);

	/**
	 * Total number of pending tasks.
	 * @returns {Promise<void>} - A Promise that resolves when the total number of pending tasks are fetched.
	 * @async
	 */
	const fetchPendingTasks = async () =>
	{
		try
		{
			if (!user) return null;
			const url = `/api/equipments/pending-tasks?username=${encodeURIComponent(user.username)}&token=${user.token}`;
			const response = await ekosliveServer.get(url);
			setCount(response.data.count);
		}
		catch (error)
		{
			captureException(error);
		}
	};
	
	useEffect(() => {
		fetchPendingTasks();
	}, [])

	useEffect(() => {
		handleClose();
	}, [pathname, handleClose]);

	return (
		<>
			<Can action="update" subject="ModeratingStatus" passThrough>
				{(allowed) =>
					allowed ? (
						<Badge
							sx={{
								border: `1px solid ${palette.grey[200]}`,
								cursor: "pointer",
								padding: ".5rem",
								borderRadius: "8px",
								":hover": {
									backgroundColor: palette.primary.main,
									borderColor: "transparent",
								},
							}}
							color="primary"
							badgeContent={count.reduce((a, b) => a + b, 0)}
							onClick={handleClick}
							onMouseEnter={handleClick}
						>
							<UserIcon width={20} height={20} color={palette.white[900]} />
						</Badge>
					) : (
						<button
							aria-describedby="user-menu-popover"
							onMouseEnter={handleClick}
							onClick={handleClick}
							className="p-2 border text-[#EFEFEF] border-[#EFEFEF] rounded-lg flex items-center gap-1 transition-colors focus-button hover:text-[#EFEFEF] hover:bg-primary-orange hover:border-transparent"
						>
							<UserIcon width={20} height={20} color="currentColor" />
						</button>
					)
				}
			</Can>
			<Popover
				id="user-menu-popover"
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				marginThreshold={36}
				slotProps={{
					paper: {
						sx: {
							backgroundColor:
								pathname === "/"
									? `${palette.common.white}0F`
									: palette.black[700],
							borderRadius: "8px",
							marginTop: ".5rem",
							width: "180px",
						},
						onMouseLeave: handleClose,
					},
				}}
				hideBackdrop
				keepMounted={false}
				disablePortal={false}
				disableEnforceFocus
				disableAutoFocus
			>
				<PopoverList handleClose={handleClose} />
			</Popover>
		</>
	);
};

export default UserPopover;
