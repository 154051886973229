const palette = {
	primary: {
		main: "#FF6600",
	},
	error: {
		main: "#EA001C",
	},
	common: {
		white: "#FFFCFA",
		black: "#151515",
	},
	success: {
		main: "#00D254",
		dark: "#167412",
	},
	grey: {
		100: "#F2F4F7",
		200: "#EFEFEF",
		300: "#424242",
		main: "#CECECE",
	},
	orange: {
		100: "#FF660026",
		300: "#FFD9C0",
		500: "#FFB380",
		700: "#FF8C40",
		900: "#252525",
	},
	black: {
		400: "#333333",
		500: "#252525",
		600: "#272727",
		700: "#1D1D1D",
		800: "#0F0E0E",
		900: "#101010",
	},
	white: {
		900: "#FFFFFF",
	},
};

export default palette;
