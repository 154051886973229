import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTheme, Badge } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { logout } from "@redux/actions";
import ArrowsIcon from "@client/icons/Arrows";
import LogoutIcon from "@client/icons/Logout";
import User from "@client/icons/UsersIcon";
import { Can } from "@components/providers/AclProvider";
import { useModerationContext } from "@components/providers/ModerationProvider";

const items = [
	{
		icon: <User width={16} height={16} />,
		label: "Profile",
		action: "profile",
	},
	{
		icon: <ArrowsIcon width={14} height={11} />,
		label: "Subscription",
		action: "manager",
	},
	{
		icon: <ArrowsIcon width={14} height={11} />,
		label: "Moderation",
		action: "moderation",
		condition: {
			can: "update",
			subject: "ModeratingStatus",
		},
	},
	{
		icon: <LogoutIcon width={16} height={16} />,
		label: "Logout",
		action: "logout",
	},
];

const PopoverList = ({ handleClose }) =>
{
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [, setSearchParams] = useSearchParams();
	const { palette } = useTheme();
	const { count } = useModerationContext();

	const handleItemClick = (item) => {
		switch (item.action) {
			case "profile":
				handleClose?.();
				navigate("/community/profile");
				break;
			case "manager":
				handleClose?.();
				setSearchParams({ manager: 'true' });
				break;
			case "moderation":
				handleClose?.();
				navigate("/community/moderation");
				break;
			case "logout":
				handleClose?.();
				navigate("/");
				dispatch(logout());
				break;
			default:
				return;
		}
	};

	return (
		<List
			sx={{
				display: "flex",
				flexDirection: "column",
				marginTop: ".5rem",
				margin: 0,
				padding: 0,
			}}
		>
			{items.map((item) =>
			{
				if (item.condition)
				{
					return (
						<Can
							action={item.condition.can}
							subject={item.condition.subject}
							key={item.action}
						>
							<ListItemButton
								sx={{
									width: '180px',
									display: "inline-grid",
									justifyContent: "flex-start",
									alignItems: "center",
									gridTemplateColumns: "26px 1fr",
									gap: ".625rem",
									backgroundColor: "transparent",
									cursor: "pointer",
									minWidth: "180px",
									padding: ".625rem .75rem",
									":hover": {
										backgroundColor: `${palette.white[900]}1A`,
									},
								}}
								onClick={() => handleItemClick(item)}
							>
								<Typography
									display="inline-grid"
									sx={{
										width: 26,
										height: 26,
											placeItems: "center",
											backgroundColor: `${palette.white[900]}33`,
											borderRadius: "50%",
										}}
										component="span"
									>
										{item.icon}
									</Typography>

									<Typography component="span">
										<Badge
											color="primary"
											badgeContent={count.reduce((a, b) => a + b, 0)}
											sx={{
												'& .MuiBadge-badge': {
													right: -5
												}
											}}
										>
												{item.label}
										</Badge>
									</Typography>
								</ListItemButton>
							</Can>
						);
					}
					return (
						<ListItemButton
							sx={{
								display: "inline-grid",
								justifyContent: "flex-start",
								alignItems: "center",
								gridTemplateColumns: "26px 1fr",
								gap: ".625rem",
								backgroundColor: "transparent",
								cursor: "pointer",
								minWidth: "180px",
								padding: ".625rem .75rem",
								":hover": {
									backgroundColor: `${palette.white[900]}1A`,
								},
							}}
							onClick={() => handleItemClick(item)}
							key={item.action}
						>
							<Typography
								display="inline-grid"
								sx={{
									width: 26,
									height: 26,
									placeItems: "center",
									backgroundColor: `${palette.white[900]}33`,
									borderRadius: "50%",
								}}
								component="span"
							>
								{item.icon}
							</Typography>
							<Typography component="span">{item.label}</Typography>
						</ListItemButton>
					);
				})}
			</List>
		);
	};

export default PopoverList;
